import Layout from '../components/Layout';
import * as React from 'react';
import { Box, Typography } from '@material-ui/core';

interface ErrorPageProps {
  title?: string
}

export default function ErrorPage ({ title = 'Something went wrong' }: ErrorPageProps) {
  return (
    <Layout loginRedirectUrl='/en/dashboard'>
      <Box padding={10}>
        <Typography
          component='h1'
          variant='h2'
          align='center'
        >
          {title}
        </Typography>
      </Box>
    </Layout>
  );
}
